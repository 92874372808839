import { colors } from '../styles/colors';
import { css } from '@emotion/core';
import config from '../website-config';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faDiscord, faSteam, faKickstarter } from '@fortawesome/free-brands-svg-icons'

const SocialCSS = css`
  a {
    color: white;
  }
  a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`

const SocialFooter = css`
`;

const SocialHeader = css`
  a {
    color: ${colors.logoPurple};
  }
  a:hover {
    color: ${colors.caveorange};
    text-decoration: none;
  }
`;

const SocialLink = css`
display: flex;
justify-content: center;
align-items: center;
margin: 0;
padding: 4px;

@media (min-width: 640px) {
  padding: 12px;
}

svg:hover {
  fill: #fff;
}

svg {
  height: 2rem;
  fill: ${colors.cavelinks};
  
  @media (min-width: 640px) {
    height: 3.5rem;
  }
}
`;

type Props = {
    type?: "header" | "footer"
}

export const Social: React.FC<Props> = (props) => {
    return <div css={[SocialCSS, props.type ? SocialHeader : SocialFooter]}>
        {config.discord && (
            <a css={[SocialLink]} href={config.discord} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="2x" icon={faDiscord} />
            </a>
        )}
        {config.twitter && (
            <a css={[SocialLink]} href={config.twitter} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="2x" icon={faTwitter} />
            </a>
        )}
    </div>
}