import { Link } from 'gatsby';
import * as React from 'react';
import { css } from '@emotion/core';

import { outer, inner } from '../styles/shared';
import config from '../website-config';
import { Social } from "./Social"
import { colors } from '../styles/colors';


const Copyright = css`
  a {
    color: inherit;
  }
`;


const SiteFooter = css`
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  color: #fff;
  background: ${colors.logoOrange};
  width: 100%;
`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;
  flex-direction: column;
`;

const Footer: React.FC = () => {

  return (
    <footer css={[outer, SiteFooter]}>
      <Social />
      <div css={[inner, SiteFooterContent, Copyright]}>
        <a href="https://docs.google.com/document/d/e/2PACX-1vTxf96_8Ec_qcj62Ilf0Dj8mdhOw7AwYr6D1FO-wQTK6sEWBjWvD3DlTZ6KZFjtk5WsFs_9e-A_ciOb/pub" target="#">Community Code of Conduct</a>
        <section>
          <Link to="/">{config.title}</Link> &copy; {new Date().getFullYear()}{' '}
          {config.footer && (
            <Link to="/">
              | {config.title} {config.footer}
            </Link>
          )}
        </section>
      </div>
    </footer>
  );
};

export default Footer;
