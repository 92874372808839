import "../styles/mui.scss";

import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import { inner, outer, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import Footer from '../components/Footer';
import "react-image-gallery/styles/scss/image-gallery.scss";

import { SiteHelmet } from "../components/Helmet";

import FixedBar from "../components/FixedBar";
import { MailSection } from "../components/MailSection";

const WrapperCss = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PageTemplate = css`
  .site-main {
    width: 100%;
  }
`;

const NotFoundCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 680px;
  margin-top: 0px;
  height: 85vh;
  font-size: 2em;
  @media (max-height: 400px) {
    padding-top: 96px;
    padding-bottom: 16px;
    height: auto;
  }
`;


type Props = {
  data: any
}

// Landing
const Landing: React.FC<Props> = () => {
  return <IndexLayout>
    <SiteHelmet />
    <Wrapper css={[PageTemplate, WrapperCss]}>
      <FixedBar />
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={[inner, NotFoundCSS]}>
          Page not found
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout >
}

export default Landing;
