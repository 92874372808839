import { css } from "@emotion/core";
import React from "react";
import config from "../website-config";
import { OutlineButton } from "./OutlineButton";

const ActionButtonCSS = css`
    display: flex;
    justify-content: center;
    align-items: center;

    #short {
        display: block;
    }
  
    #long {
        display: none;
    }

    font-size: 1.5rem;

    @media (min-width: 510px) {
        
    font-size: 2rem;
    #short {
        display: none;
    }
    
    #long {
        display: block;
    }
  }
`


export const ActionButton = () => {
    return <OutlineButton href={config.steam || ""}>
        <div css={[ActionButtonCSS]}>
            <div id="long">Play Now</div>
            <div id="short">Play</div>
        </div>
    </OutlineButton>;
}

export const HomeButton = () => {
    return <OutlineButton href={"/"} noBlank>
        <div css={[ActionButtonCSS]}>
            <div id="long">Home</div>
            <div id="short">Home</div>
        </div>
    </OutlineButton>;
}