import { css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { scrollSpy } from "react-scroll";
import { colors } from "../styles/colors";
import { ActionButton, HomeButton } from "./ActionButton";
import { Social } from "./Social";

const SiteBar = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 999;
  color: #fff;
  background: #fafafa;
  width: 100%;
  transition: box-shadow .25s;
  border-bottom: solid 2px ${colors.caveorange};
  
  @media (min-width: 640px) {
    padding: 12px;
  }
`;

const SiteBarShadowOnCSS = css`
  box-shadow: 0px 0px 14px 3px rgba(0,0,0,0.4);
`;

const MainContainerCSS = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 16px;
`


const FixedBar: React.FC<{ includeHome?: boolean }> = ({includeHome}) => {
    const [scrollY, setScrollY] = useState(0);
    useEffect(() => {
        if (!scrollSpy.isMounted(document)) {
            scrollSpy.mount(document);
        }

        scrollSpy.addSpyHandler((_x: number, y: number) => {
            setScrollY(y);
        }, document);
    }, []);
    return (
        <header css={[SiteBar, scrollY > 0 ? SiteBarShadowOnCSS : ""]}>
            <div css={[MainContainerCSS]}>
                <Social type="header" />
                <div style={{ flex: 1 }} />
                { includeHome && <HomeButton /> }
                <div style={{width: 8}} />
                <ActionButton />
            </div>
        </header>
    );
};

export default FixedBar;